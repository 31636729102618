import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LANG } from '@common/utility/globals';

@Injectable()
export class AcceptLanguageHeaderInterceptor implements HttpInterceptor {
  constructor(private readonly translateService: TranslateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(
      req.clone({
        headers: req.headers.append(
          'Accept-Language',
          this.translateService.currentLang || DEFAULT_LANG
        ),
      })
    );
  }
}
