import { Injectable } from "@angular/core";
import { Opco } from "@common/entities/opco.entity";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class OpcoService {
  constructor(private readonly httpClient: HttpClient,
              private readonly router: Router) {
  }

  getActiveOpco(): string {
    const pathSegments = this.router.url.split("/");
    return pathSegments.find(path => !!path); // First non-blank path segment in URL.
  }

  getOpco(opco: string) {
    return this.httpClient.get<Opco>(`/api/GetOpcoInfo`, { params: { opco } });
  }

  getAllOpcosForUser() {
    return this.httpClient.get<Opco[]>("/api/GetAllOpcosForUser");
  }

  redirectToOpco(opcoCode: string) {
    // When changing the OPCO we REFRESH the page to the root URL of the application.
    // This is intentionally a refresh because the majority of components
    // cannot handle an OPCO change at runtime (and would otherwise end up
    // in an invalid state). It also ensures that the Guards are triggered with the
    // current URL and not the in-flight URL.
    window.location.href = `${window.location.origin}/${opcoCode}`;
  }
}
