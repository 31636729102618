import { InjectionToken } from "@angular/core";

export const APP_CONFIG = new InjectionToken<AppConfig>("APP_CONFIG");

export interface AppConfig {
  /**
   * Auth0 settings. Note that this is duplicated in the settings.json files in the backend.
   */
  auth0: {
    clientId: string;
    domain: string;
    audience: string;
    scope: string;
  },
  sentry: {
    enabled: boolean;
    dsn: string,
    environment: string;
  },
  document_archive_url: string,
  dsb_url: string,
  drive_service_url: string
}
