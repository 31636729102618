import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "@app/app.module";
import { environment } from "./environments/environment";
import { APP_CONFIG } from "@app/config/app-config";

if (environment.production) {
  enableProdMode();
}

fetch("/_ng/settings.json")
.then((response) => response.json())
.then((appConfig) => {
  platformBrowserDynamic(
    [{ provide: APP_CONFIG, useValue: appConfig }]
  )
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
});
