import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ErrorService } from "@common/services/error-service.service";
import HttpStatusCode from "@common/entities/http-status-code.entity";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorService: ErrorService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((httpResponse) => {
        if (httpResponse instanceof HttpResponse) {
          if (httpResponse.ok) {
            this.errorService.clearServerErrorByKey(httpResponse.url);
          }
        }
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof ErrorEvent) {
          console.log("Client side error: " + err);
        } else {
          if (err.status === HttpStatusCode.BAD_REQUEST) {
            this.errorService.addServerError(err);
          }
        }
        return throwError(() => err);
      })
    );
  }
}
