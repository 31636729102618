import { Injectable, OnDestroy } from "@angular/core";

import { BehaviorSubject, Observable, Subject } from "rxjs";
import jwt_decode from "jwt-decode";
import { AuthService } from "@auth0/auth0-angular";
import { Auth0User } from "@common/entities/auth0.user.entity";
import { takeUntil } from "rxjs/operators";
import { Auth0Token } from "@common/entities/auth0.token.entity";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService implements OnDestroy {
  readonly permissions$ = new BehaviorSubject<string[] | undefined>(undefined);
  readonly token$ = this.authService.getAccessTokenSilently();

  private readonly onDestroy$ = new Subject<void>();

  constructor(private readonly authService: AuthService) {
    this.token$.pipe(takeUntil(this.onDestroy$)).subscribe((accessToken) => {
      const decodedAccessToken = jwt_decode(accessToken) as Auth0Token;

      this.permissions$.next(decodedAccessToken["scope"].split(" "));

      const expiryDate = new Date(decodedAccessToken.exp * 1000);
      setTimeout(
        AuthenticationService.redirectToTokenExpiredPage,
        expiryDate.getTime() - Date.now()
      );
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  isAuthenticated$() {
    return this.authService.isAuthenticated$;
  }

  user$() {
    return this.authService.user$ as Observable<Auth0User>;
  }

  hasPermission(requestedPermission: string): boolean {
    const permissions = this.permissions$.getValue();
    return permissions && permissions.some((permission) => permission === requestedPermission);
  }

  logout() {
    this.authService.logout({
      logoutParams: {
        returnTo: window.location.origin,
        federated: true,
      },
    });
  }

  private static redirectToTokenExpiredPage() {
    window.location.href = `${window.location.origin}/auth/expired`;
  }
}
