import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { ServerError, ServerMessage } from "@common/entities/server-error";
import { flatten } from "lodash";
import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: "root"
})
export class ErrorService {
  public errorMap: Map<string, HttpErrorResponse> = new Map<string, HttpErrorResponse>();

  private _errors$: BehaviorSubject<HttpErrorResponse[]> = new BehaviorSubject<HttpErrorResponse[]>(
    []
  );
  public errors$: Observable<HttpErrorResponse[]> = this._errors$.asObservable();

  addServerError(err: HttpErrorResponse) {
    this.errorMap.set(err.url, err);
    this.updateErrorMap();
  }

  clearServerErrorByKey(url: string) {
    if (this.errorMap.has(url)) {
      this.errorMap.delete(url);
      this.updateErrorMap();
    }
  }

  /**
   * Used in the @OnDestroy of a component which allows you to clear all the errors of a certain form in case the user 'cancels' a certain request.
   * Scenario: User submits a edit of transport and he inserted a wrong phone number. The error is shown on the form but after that, he cancels the 'edit transport'.
   * This means that the error is still in the key map (until a 2XX is triggered on the same key (url))
   *
   * This is not a problem as the subscription only triggers when a new error occurs.
   * So recreating the component will not trigger the validation.
   * Although when a new error (bad request) occurs and this has a different url, it will also show the phone number validation.
   * To prevent that, we clear the server errors @OnDestroy
   *
   * @param fields: The fields that were used on your component to show on your form. Ex: contactPersonPhone
   */
  clearServerErrors(fields: string[]) {
    this.findHttpErrorsBasedOnFields([...this.errorMap.values()], fields).forEach(
      (error: HttpErrorWithField) => {
        this.clearServerErrorByKey(error.httpErrorResponse.url);
      }
    );
  }

  findHttpErrorsBasedOnFields(errors: HttpErrorResponse[], fields: string[]): HttpErrorWithField[] {
    let httpErrorResponses: HttpErrorWithField[] = [];

    try {
      errors.forEach((error: HttpErrorResponse) => {
        const serverErrors = this.getServerErrors(errors);
        serverErrors.forEach((serverError: ServerError) => {
          const messages: ServerMessage[] = serverError.messages;
          messages.forEach((message: ServerMessage) => {
            if (fields.includes(message.field)) {
              httpErrorResponses = [
                ...httpErrorResponses,
                {
                  field: message.field,
                  httpErrorResponse: error,
                },
              ];
            }
          });
        });
      });
    } catch (e) {
      Sentry.setContext("Error service debug info", {
        HttpErrorResponse: JSON.stringify(errors),
        serverErrors: JSON.stringify(this.getServerErrors(errors)),
      });
      Sentry.captureException(e);
    }

    return httpErrorResponses;
  }

  private getServerErrors(errors: HttpErrorResponse[]): ServerError[] {
    return flatten(
      errors.map((httpErrorResponse: HttpErrorResponse) => httpErrorResponse.error as ServerError[])
    );
  }

  findErrorMessages(errors: HttpErrorResponse[]): string[] {
    const serverErrors = flatten(
      errors.map((httpErrorResponse: HttpErrorResponse) => httpErrorResponse.error as ServerError[])
    );

    const errorKeys: string[] = [];
    const serverMessages = flatten(serverErrors.map((value: ServerError) => value.messages));
    serverMessages.forEach((serverMessage: ServerMessage) =>
      errorKeys.push(serverMessage.translation)
    );

    return errorKeys;
  }

  private updateErrorMap() {
    this._errors$.next([...this.errorMap.values()]);
  }
}

export interface HttpErrorWithField {
  readonly field: string;
  readonly httpErrorResponse: HttpErrorResponse;
}
