import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UnknownOpcoHandlerComponent } from "@app/components/unknown-opco-handler/unknown-opco-handler.component";
import { AuthGuard } from "@auth0/auth0-angular";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () =>
      import("@auth/authentication.module").then((module) => module.AuthenticationModule),
  },
  {
    path: ":opco",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("@application/application.module").then((module) => module.ApplicationModule),
  },
  {
    path: "**",
    canActivate: [AuthGuard],
    component: UnknownOpcoHandlerComponent,
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
