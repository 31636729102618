import { Component, OnDestroy, OnInit } from "@angular/core";
import { OpcoService } from "@common/services/opco.service";
import { Subject } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { AuthenticationService } from "@common/services/authentication.service";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";

/**
 * When the user loads the application, this component gets called
 * if the URL does NOT directly match with a known route.
 *
 * For example:
 * - localhost:4200 -> redirect IS called
 * - localhost:4200/CZ01/ -> redirect NOT called
 * - localhost:4200/CZ01/transports -> redirect NOT called
 * - localhost:4200/CZ01/unknownPath -> redirect IS called
 * - localhost:4200/invalidOpco/transports -> redirect NOT called (and left unhandled)
 */
@Component({
  selector: "qplanning-unknown-opco-handler",
  template: ""
})
export class UnknownOpcoHandlerComponent implements OnInit, OnDestroy {

  private readonly onDestroy$ = new Subject<void>();
  private attempts: number = 0;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly opcoService: OpcoService,
    private readonly router: Router
  ) {
  }

  ngOnInit() {
    this.redirectToDefaultOpco();
  }

  private redirectToDefaultOpco() {
    this.attempts++;
    this.opcoService.getAllOpcosForUser().pipe(takeUntil(this.onDestroy$))
    .subscribe((opcos) => {
        if (opcos.length === 0) {
          this.redirectToUnauthorized();
        } else {
          this.opcoService.redirectToOpco(opcos[0].code);
        }
      },
      (error: HttpErrorResponse) => {
        if (error.status === 401 && this.attempts < 10) {
          setTimeout(() => {
            this.redirectToDefaultOpco();
          }, 1000);
        } else {
          this.redirectToUnauthorized();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  logout() {
    this.authenticationService.logout();
  }

  private redirectToUnauthorized() {
    this.router.navigate(["auth", "unauthorized"]);
  }
}
