import { Component, inject, OnInit } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LANG } from '@common/utility/globals';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { SentryService } from "@app/config/sentry.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  private readonly sentryService = inject(SentryService); // Required to initialize Sentry!

  constructor(
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly gtmService: GoogleTagManagerService
  ) {
    this.initGtm();
  }

  private static getUserLanguageFromStorage() {
    return window.localStorage.getItem('language') ?? DEFAULT_LANG;
  }

  ngOnInit(): void {
    this.translateService.setDefaultLang(DEFAULT_LANG);
    this.translateService.use(AppComponent.getUserLanguageFromStorage());
  }

  private initGtm() {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }
}
