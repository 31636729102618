import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UnknownOpcoHandlerComponent } from '@app/components/unknown-opco-handler/unknown-opco-handler.component';
import { ErrorInterceptor } from '@common/interceptor/back-error.interceptor';
import { AuthClientConfig, AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { defineCustomElements } from '@mateco/zenith-webcomponents/loader';
import { AcceptLanguageHeaderInterceptor } from '@common/interceptor/accept-language-header.interceptor';
import { registerLocaleData } from "@angular/common";
import localeCS from '@angular/common/locales/cs';
import localeHU from '@angular/common/locales/hu';
import localeNL from '@angular/common/locales/nl';
import localePL from '@angular/common/locales/pl';
import localeES from '@angular/common/locales/es';
import localeFR from '@angular/common/locales/fr';
import localeDE from '@angular/common/locales/de';
import { APP_CONFIG } from "@app/config/app-config";
import { initializeAuth0Client } from "@app/config/initialize-auth0-client";
import { AppComponent } from './app.component';
import * as Sentry from "@sentry/angular";

export function createTranslateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './_ng/translations/', '.json');
}

registerLocaleData(localeES);
registerLocaleData(localeNL);
registerLocaleData(localeCS);
registerLocaleData(localeHU);
registerLocaleData(localeDE);
registerLocaleData(localeFR);
registerLocaleData(localePL);

@NgModule({
  declarations: [AppComponent, UnknownOpcoHandlerComponent],
  imports: [
    AuthModule.forRoot(/* Config initialized via APP_INITIALIZER (initializeAuth0Client()) below. */),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAuth0Client,
      deps: [AuthClientConfig, APP_CONFIG],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageHeaderInterceptor,
      multi: true,
    },
    { provide: 'googleTagManagerId', useValue: 'GTM-MRDSRJ5D' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    defineCustomElements();
  }
}
