import {AuthClientConfig} from '@auth0/auth0-angular';
import {AppConfig} from './app-config';

export function initializeAuth0Client(authClientConfig: AuthClientConfig, appConfig: AppConfig) {
  return () => {
    authClientConfig.set({
      authorizationParams: {
        audience: appConfig.auth0.audience,
        redirect_uri: `${window.location.origin}/auth/callback`,
        scope: appConfig.auth0.scope,
      },
      clientId: appConfig.auth0.clientId,
      domain: appConfig.auth0.domain,
      httpInterceptor: {
        allowedList: ['/api/*', `${appConfig.drive_service_url}/api/*`],
      },
    });
  };
}
